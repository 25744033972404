.float-left {
    float: left;
  }

.float-right {
    float: right;
}
  
  .header-container {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
  
  .ui.container.picture-container {
    position: relative;
  }
  
  .refresh-time {
    color: green;
    margin-top: 1rem;
  }
  .arrow-container {
    position: absolute;
    top: 155px;
    left: 190px;
    transition: top linear 1s, left linear 1s;
  }
  
  .arrow-container.dead {
    top: 675px;
    left: 150px;
  }
  
  .blockHead {
    background-color: red;
    /*width: 150px; */
    height: 40px;
    line-height: 40px;
    display: inline-block;
    position: relative;
    padding-left: 6px
  }
  
  .blockHead:after {
    color: red;
    border-left: 20px solid;
    border-top: 20px solid transparent;
    border-bottom: 20px solid transparent;
    display: inline-block;
    content: '';
    position: absolute;
    right: -20px;
    top:0
}
  
.clear-both { 
    clear: both;
}