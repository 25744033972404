.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.float-left {
  float: left
}

.header-container {
  
}

.container {
  position: relative;
}

.arrow-container {
  position: absolute;
  top: 155px;
  left: 190px;
  transition: top linear 1s, left linear 1s;
}

.arrow-container.dead {
  top: 675px;
  left: 150px;
}

.blockHead {
  background-color: red;
  /*width: 150px; */
  height: 40px;
  line-height: 40px;
  display: inline-block;
  position: relative;
  padding-left: 6px
}

.blockHead:after {
  color: red;
  border-left: 20px solid;
  border-top: 20px solid transparent;
  border-bottom: 20px solid transparent;
  display: inline-block;
  content: '';
  position: absolute;
  right: -20px;
  top:0
  }
